import axios from 'axios';

import { AppSettings } from '../models/app-settings';

export const getAppSettings = (): Promise<AppSettings | undefined> => {
    return axios({
        url: `settings/environment.json?cb=${Date.now()}`,
        baseURL: '/',
    })
        .then((result) => {
            result.data.EXERCISE_PLAYER_ROOT =
                result.data.EXERCISE_PLAYER_ROOT +
                `/remoteEntry.js?cb=${Date.now()}`;
            return { ...result.data, loaded: true };
        })
        .catch(() => {
            return undefined;
        });
};
